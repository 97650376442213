import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";

import TestimonialsSection from "../components/TestimonialsSection";
import FeatureGrid, { Feature } from "../components/Features";

import Img from "gatsby-image";
import LogoGrid from "../components/Logos";

import SubscribeBox from "../components/SubscribeForm";
import { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

import pharmacyChart from "../img/charts/chart-lost-revenue.svg";

import {
  faTruck,
  faLock,
  faList,
  faSmile,
} from "@fortawesome/pro-regular-svg-icons";

const ProvidersPage = ({ data }) => {
  const { markdownRemark: page, logoImages } = data;

  return (
    <Layout
      headerTitle={page.frontmatter.heading}
      headerPhoto={
        <Img
          imgStyle={{
            objectFit: "contain",
            objectPosition: "100% 100%",
          }}
          fluid={page.frontmatter.headerPhoto.childImageSharp.fluid}
        />
      }
      headerClassName="bg-goldenrod curve-br"
      className="accent-goldenrod"
    >
      <Helmet titleTemplate="%s">
        <title>{`${page.frontmatter.title}`}</title>
        <meta property="og:title" content={page.frontmatter.title} />
      </Helmet>

      <section className="container mt-8 pb-8 md:pb-12 lg:pb-16">
        <div className="columns">
          <div className="w-full lg:w-10/12">
            <h2 className="font-display font-bold text-3xl text-accent-m1 leading-tight">
              {page.frontmatter.subtitle}
            </h2>
          </div>
        </div>

        <div className="columns">
          <div className="w-full md:w-5/12 lg:w-4/12">
            <p className="text-xl leading-relaxed mb-3">
              {page.frontmatter.lead_paragraph}
            </p>

            <Link
              to={page.frontmatter.lead_cta_action}
              className="btn mt-3 btn-accent btn-avenir-semibold md:w-full"
            >
              {page.frontmatter.lead_cta}
            </Link>
          </div>
          <div className="w-0 hidden lg:block lg:w-1/12"></div>
          <div className="w-full md:w-7/12 lg:w-5/12 space-y-4">
            {
              // TODO: figure out how best to put this into the template for CMS
            }
            <p className="text-lg">
              Stop waiting for patients to pick up their refills. Send
              prescriptions directly to the people who need them.
            </p>

            <ul className="space-y-2 ml-5 lg:ml-0 list-disc">
              <li>Make reminder phone calls a thing of the past</li>

              <li>
                Increase ROI by reducing return-to-stock and improving inventory
                turnover
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="bg-accent-p2">
        <section className="py-6 pb-10 md:py-8 md:pb-20 lg:py-16 lg:pb-30">
          <div className="container">
            <div className="columns items-center">
              <div className="w-full md:w-5/12 lg:w-4/12 space-y-3">
                <h3 className="font-display font-bold text-3xl text-accent-m2 leading-tight">
                  Improve Your Patients' Health
                </h3>
                <p className="text-xl leading-relaxed">
                  Patients need reliable access to their prescriptions more than
                  ever.
                </p>
              </div>
              <div className="w-0 hidden lg:block lg:w-1/12"></div>
              <div className="w-full md:w-7/12 lg:w-5/12">
                <ul className="space-y-2 ml-5 lg:ml-0 list-disc">
                  <li>Delivery improves a patient’s adherence to therapy</li>
                  <li>
                    Improved adherence to therapy contributes to positive health
                    outcomes
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="py-6 md:py-8 lg:py-16">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full lg:w-10/12">
              <FeatureGrid>
                <Feature
                  headingClassName="text-accent-m1"
                  heading="Keep the workflow simple"
                  icon={faTruck}
                >
                  <p className="mb-2">
                    Manage delivery within pharmacy software
                  </p>
                  <p className="mb-2">Real-time status updates</p>
                  <p className="mb-2">Co-pay collection or buy-down</p>
                  <p className="mb-2">High-touch customer service</p>
                </Feature>
                <Feature
                  headingClassName="text-accent-m1"
                  heading="Retain control"
                  icon={faLock}
                >
                  <p className="mb-2">Choose cost for patient</p>
                  <p className="mb-2">Tamper-evident packaging available</p>
                  <p className="mb-2">
                    Long-term storage of signatures and delivery details
                  </p>
                </Feature>
                <Feature
                  headingClassName="text-accent-m1"
                  heading="Choose from multiple service levels"
                  icon={faList}
                >
                  <p className="mb-2">Same-day Delivery</p>
                  <p className="mb-2">On-demand Delivery</p>
                  <p className="mb-2">Shipping</p>
                </Feature>
                <Feature
                  headingClassName="text-accent-m1"
                  heading="Improve the patient experience"
                  icon={faSmile}
                >
                  <p className="mb-2">
                    Secure delivery from pharmacy to patient’s doorstep
                  </p>
                  <p className="mb-2">
                    Access to a nationwide courier network with built-in
                    redundancies
                  </p>
                </Feature>
              </FeatureGrid>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-navy py-6 md:py-8 lg:py-12">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full md:w-6/12 lg:w-7/12">
              <p className="text-white text-lg lg:text-xl leading-relaxed lg:pr-16">
                A metropolitan health center implemented ScriptDrop’s solution
                and saw a substantial drop in revenue lost to prescriptions
                returned to stock.
              </p>
            </div>

            <figure className="w-full md:w-6/12 lg:w-5/12 text-center space-y-2 md:space-y-4">
              <img
                className="w-full sm:w-3/4 md:w-full mx-auto"
                alt="Bar chart showing revenue lost due to prescriptions returned to stock."
                src={pharmacyChart}
              />
              <figcaption>
                <p className="text-accent-p2">
                  Revenue Lost Due to Return to Stock
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section className="py-8 lg:py-12 xl:pt-16">
        <div className="container">
          <h3 className="text-accent-m2 font-heading text-3xl lg:text-4xl text-center mb-2 lg:mb-4">
            Trusted by Our Partners
          </h3>
          <div className="columns">
            <LogoGrid
              images={logoImages.nodes}
              className="w-10/12 sm:w-8/12 md:w-7/12"
            />
          </div>
        </div>
      </section>

      <TestimonialsSection
        className="my-8"
        testimonials={[
          {
            quote:
              "...when ScriptDrop came along and was able to offer affordable prescription delivery that allowed a pharmacy to stay within their workflow, it was a no-brainer…",
            author:
              "Hemal Desai | BestRx Pharmacy Software | President, Pharmacy Health and Wellness",
          },
        ]}
      />

      <section className="py-6 md:py-8 lg:py-16">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full md:w-6/12 lg:w-5/12">
              <SubscribeBox title="Newsletter" />
            </div>

            <div className="hidden md:block w-1/12">&nbsp;</div>

            <div className="w-full text-center sm:text-left md:w-5/12 lg:w-4/12">
              <h4 className="font-display font-bold leading-tight text-2xl lg:text-3xl">
                Want to learn more?
              </h4>
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: page.frontmatter.footer_cta_content,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <article className="container">
        <HTMLContent
          className="prose lg:prose-lg max-w-none"
          content={page.html}
        />
      </article>
    </Layout>
  );
};

export default ProvidersPage;

export const providerPageQuery = graphql`
  query PharmaciesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subtitle
        lead_paragraph
        lead_cta
        lead_cta_action
        footer_cta_content
        headerPhoto {
          childImageSharp {
            fluid(
              maxWidth: 256
              quality: 80
              traceSVG: { background: "transparent", color: "#E3A04C" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    logoImages: allFile(
      filter: { relativeDirectory: { eq: "logos/pharmacy" } }
    ) {
      nodes {
        ...logoGridImage
      }
    }
  }
`;
