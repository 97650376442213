import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const Testimonials = ({ testimonials, className }) => (
  <section className={`bg-gray-dot bg-repeat-x bg-center ${className}`}>
    <div className="container">
      <div className="columns">
        <div className="w-full sm:w-10/12 md:w-11/12 bg-white">
          <div className="bg-accent-p1 space-y-2 font-medium p-4 md:p-8 lg:p-12 rounded-lg md:rounded-xl w-full md:w-10/12 mx-auto">
            {testimonials.map((testimonial) => (
              <article
                key={v4()}
                className="space-y-2 md:space-y-4 md:py-4 md:px-4 lg:px-12 xl:px-16"
              >
                <p className="md:text-lg lg:text-xl text-accent-reverse">
                  “{testimonial.quote}”
                </p>
                <p className="text-sm md:text-md lg:text-lg text-navy-m1">
                  &ndash;&nbsp;{testimonial.author}
                </p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;
